<template>
  <div class="right_form">
    <div class="back_left">
      <img src="@/assets/teachterCard/Left.png"
           @click="$emit('Back')"
           alt="">
    </div>
    <div class="block">
      <div class="title">
        请填写基本信息
      </div>
    </div>
    <div class="form">
      <div class="custom-cascader">

        <div class="warp">
          <div class="line"
               style="margin-top:16px">
            <el-input clearable
                      v-model="form.userName"
                      placeholder="请输入姓名">
            </el-input>
          </div>
          <div class="line"
               style="margin-top:16px">
            <el-input clearable
                      placeholder="请输入学校"
                      v-model="form.description">
            </el-input>

          </div>
          <el-select v-model="form.subjects"
                     style="margin-top:16px"
                     placeholder="选择科目"
                     @change="getSelectedSubjectId">
            <el-option @click="getSubjects"
                       v-for="item in subjectList"
                       :key="item.subject_id"
                       :label="item.subject_name"
                       :value="item.subject_name">
            </el-option>
          </el-select>
          <div class="btn"
               :loading="isLoading"
               @click="submitIMForm">
            完成
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import { globalData } from '@/views/teacherCard/eventBus/eventBus.js'
export default {
  data () {
    return {
      isLoading: false,
      form: {
        userName: '',
        description: '',
        subjects: ''//选中科目
      },
      subjectList: [],//科目列表
      subjectId: '',//用于存储选中的科目对应的 ID
      uniqid: ''
    }
  },
  mounted () {
    this.getSubjects();
  },
  methods: {
    getSubjects () {
      this.$http({
        url: '/api/v1/card/subject',
        method: 'get'
      }).then(res => {
        console.log(res);
        if (res.status === 200) {
          //请求成功
          this.subjectList = res.data.list; //将从后端返回的科目列表存入 subjectList 数组
        } else {
          //请求失败，给出提示信息
          this.$message.error(res.msg);
        }

      })
    },
    getSelectedSubjectId () {
      //根据选中的科目名称查找对应的科目对象
      console.log(55);
      const subjects = this.subjectList.find(item =>
        item.subject_name === this.form.subjects
      );
      if (subjects) {
        //找到了对应的科目对象，保存其 ID 至 subjectId 变量
        this.subjectId = subjects.subject_id;
      } else {
        this.$message.error('未找到选中的科目！');
        this.subjectId = '';
      }
    },
    submitIMForm () {

      const uniqid = globalData.unqid
      console.log(uniqid);
      if (!this.form.subjects) {
        // 如果未选择科目，则显示提示信息
        this.$message.error('请选择科目！');
        return;
      }
      this.isLoading = !this.isLoading
      this.$http({
        url: '/api/v1/card/register',
        method: 'post',
        data: {
          uniqid: globalData.unqid,
          edu_user_name: this.form.userName,
          description: this.form.description,
          subject_ids: this.subjectId
        }
      }).then(res => {
        if (res.status === 200) {
          //请求成功，给出成功提示并跳转到成功页面
          this.$emit('step-successNext'); // 验证成功，通知父组件

        } else {
          //请求失败，给出提示信息
          this.$message.error(res.msg);
        }

      })
    }
  }

}
</script>
<style lang="scss" scoped>
.right_form {
  width: 50%;
  .back_left {
    padding: 16px 0 0 16px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
  }
  .title {
    display: flex;
    justify-content: center;
    height: 39px;
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    margin-top: 10px;
  }

  .form {
    display: flex;
    justify-content: center;
    margin-top: 53px;
    .custom-cascader {
      .el-cascader__dropdown {
        width: 100%;
        max-height: 100%;
      }
    }
    .warp {
      width: 100%;
      max-width: 390px;

      .line {
        width: 100%;
      }
      .btn {
        margin: 0 auto;
        width: 336px;
        height: 48px;
        background: #2196f3;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 16px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        margin-top: 40px;
      }
    }
  }
}

::v-deep .el-input__inner {
  font-size: 14px !important;
    height: 48px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0%;
    line-height: 40px;
}

::v-deep .el-select {
  width: 100%;
}
</style>